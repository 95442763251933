import React from "react";
import "./Main.css";
import {Link} from "react-router-dom";

import arrowLeft from '../../../../assets/images/arrow-left-light.png';
import arrowRight from '../../../../assets/images/arrow-right-light.png';
import {ButtonPrimary} from "../../../../components/index";

function Content({post}) {
    const {type, title, image, date, neighborPost} = post;

    return (
        <section className={'post__main'} style={{
            backgroundImage: `url(${image})`
        }}>
            <div className={'post__main-wrapper'}>
                <div className={'post__main-info'}>
                    <h1>{title}</h1>
                    <div className={'post__main-info-date'}>{date.toLocaleDateString('en-GB')}</div>
                    <Link to={'/feedback'} className={'post__main-info-more'}>
                        <ButtonPrimary text={"Дізнатись більше"}></ButtonPrimary>
                    </Link>
                </div>
                <ul className={'post__main-nav'}>
                    <li>
                        <Link to={`/${type}/${neighborPost[0]}`}>
                            <img src={arrowLeft} title={'Попередня публікація'} alt={'Попередня публікація'}/>
                            Попередня публікація
                        </Link>
                    </li>
                    <li>
                        <Link to={`/${type}/${neighborPost[1]}`}>
                            Наступна публікація
                            <img src={arrowRight} title={'Наступна публікація'} alt={'Наступна публікація'}/>
                        </Link>
                    </li>
                </ul>
            </div>
        </section>
    );
}

export default Content;
