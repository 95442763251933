import React, {useEffect, useState} from "react";
import ButtonPrimary from '../../../../../../components/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '../../../../../../components/ButtonSecondary/ButtonSecondary';
import VideoModal from "../../../../../../components/VideoModal/VideoModal";
import PhotoModal from "../../../../../../components/PhotoModal/PhotoModal";
import "./NewsGallery.css";

import arrowLeft from '../../../../../../assets/images/arrow-left.png';
import arrowRight from '../../../../../../assets/images/arrow-right.png';

function NewsGallery({gallery: galleryData}) {
    const [gallery, setGallery] = useState([]);
    const [type, setType] = useState('photo');
    const [slideCount, setSlideCount] = useState(0);
    const [videoURL, setvideoURL] = useState(null);
    const [photoModal, setPhotoModal] = useState(null);
    const random = Math.random().toString().replace('.', 'a');
    const filterGallery = (type = 'photo') => {
        let tempGallery = galleryData.filter((item) => (item.type === type));
        const newGallery = [];
        // Prepare gallery to display (two elements on block)
        for(let i = 0; i < tempGallery.length; i++){
            if(i + 1 <= tempGallery.length - 1){
                newGallery.push([tempGallery[i], tempGallery[i + 1]]);
            }else{
                newGallery.push([tempGallery[i]]);
            }
        }

        setGallery(newGallery);
        setType(type);
    }

    useEffect(() => {
        filterGallery('photo');
    }, []);
    useEffect(() => {
        let activeSlideIndex = slideCount;

        if(slideCount < 0){
            setSlideCount(gallery.length - 1);
            activeSlideIndex = gallery.length - 1;
        }else if(slideCount >= gallery.length){
            setSlideCount(0);
            activeSlideIndex = 0;
        }

        const points = document.querySelectorAll(`.point${random}`);

        if(points.length > 0) {
            points.forEach((point) => {
                point.classList.remove('active');
            });

            points[activeSlideIndex].classList.add('active');
        }
    });

    return (
        <div className={'news-gallery__wrapper'}>
            {
                type === 'photo' ? (
                    <div className={'news-gallery__menu'}>
                        <ButtonPrimary type={'dark'} text={'Фото'} onclick={() => {
                            filterGallery('photo');
                        }}></ButtonPrimary>
                        <ButtonSecondary text={'Відео'} onclick={() => {
                            filterGallery('video');
                        }}></ButtonSecondary>
                    </div>
                ) : (
                    <div className={'news-gallery__menu'}>
                        <ButtonSecondary text={'Фото'} onclick={() => {
                            filterGallery('photo');
                        }}></ButtonSecondary>
                        <ButtonPrimary type={'dark'} text={'Відео'} onclick={() => {
                            filterGallery('video');
                        }}></ButtonPrimary>
                    </div>
                )
            }

            <div className={'news-gallery__slider'}>
                <div className={'news-gallery__slider-block'}
                     style={{
                        top: -300 * slideCount
                    }}
                >
                    {
                        gallery.length > 0 && gallery.map((galleryItem) => {
                            console.log(galleryItem);
                            return (
                                <div key={Math.random()} className={'news-gallery__block'}>
                                    {type === 'video' ? (
                                        galleryItem.map((item) => (
                                            <div
                                                className={'news-gallery__item video'}
                                                key={Math.random()}
                                                onClick={() => {
                                                     setvideoURL('https://www.youtube.com/embed/6nmQzmLzII8');
                                                 }}
                                            >
                                                <img src={item.src}/>
                                            </div>
                                        ))
                                    ) : (
                                        galleryItem.map((item) => (
                                            <div
                                                className={'news-gallery__item'}
                                                key={Math.random()}
                                                onClick={() => {
                                                    setPhotoModal(item.src);
                                                }}
                                            >
                                                <img src={item.src}/>
                                            </div>
                                        ))
                                    )}
                                </div>
                            );
                        })
                    }
                </div>
                <ul className={'news-gallery__point-list'}>
                    <li className={'news-gallery__arrow left'}>
                        <img
                            src={arrowLeft}
                            title={'left'}
                            alt={'left'}
                            onClick={() => {
                                setSlideCount(slideCount - 1);
                            }}
                        />
                    </li>
                    {gallery.map((item, index) => {
                        return <li key={Math.random()} className={`point point${random} ${index === 0 ? 'active' : ''}`} onClick={() => {
                            setSlideCount(index);
                        }}></li>
                    })}
                    <li className={'news-gallery__arrow right'}>
                        <img
                            src={arrowRight}
                            title={'right'}
                            alt={'right'}
                            onClick={() => {
                                setSlideCount(slideCount + 1);
                            }}
                        />
                    </li>
                </ul>
            </div>

            {videoURL && (<VideoModal videoURL={videoURL} closeParent={() => {
                setvideoURL(null);
            }}/>)}

            {photoModal && (<PhotoModal photo={photoModal} closeParent={() => {
                setPhotoModal(null);
            }}/>)}
        </div>
    );
}

export default NewsGallery;
