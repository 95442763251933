import React, {useEffect, useState} from "react";
import CourseItem from './components/CourseItem/CourseItem';
import {HorizontalSlider} from '../../../../../../components/index';
import "./CoursesList.css";


import arrowLeft from '../../../../../../assets/images/arrow-left.png';
import arrowRight from '../../../../../../assets/images/arrow-right.png';

function CoursesList({title, data, events, full = false, count = 2}) {
    const [gallery, setGallery] = useState(() => {
        const newGallery = [];
        // Prepare gallery to display (two elements on block)
        for (let i = 0; i < data.length; i += count) {
            console.log(`Index: ${i}; count: ${count}; dataLen: ${data.length};`, data.slice(i, i + count));
            if (i + count <= data.length) {
                newGallery.push(data.slice(i, i + count));
            } else {
                newGallery.push(data.slice(i, i + data.length));
            }
        }

        return newGallery;
    });
    const [slideCount, setSlideCount] = useState(0);
    const random = Math.random().toString().replace('.', 'a');

    useEffect(() => {
        let activeSlideIndex = slideCount;

        if (slideCount < 0) {
            setSlideCount(gallery.length - 1);
            activeSlideIndex = gallery.length - 1;
        } else if (slideCount >= gallery.length) {
            setSlideCount(0);
            activeSlideIndex = 0;
        }

        const points = document.querySelectorAll(`.point${random}`);

        if (points.length > 0) {
            points.forEach((point) => {
                point.classList.remove('active');
            });

            points[activeSlideIndex].classList.add('active');
        }
    });

    return (
        <div className={'cources'}>
            <h3>{title}</h3>
            <div className={'cources-list__wrapper'}>
                <div className={'cources__slider'} style={{
                    width: full ? '100%' : 'auto'
                }}>
                    <div className={'cources__slider-block'}
                         style={{
                             top: -512 * slideCount
                         }}
                    >
                        {
                            gallery.length > 0 && gallery.map((galleryItem) => {
                                return (
                                    <div key={Math.random()} className={'cources__block'}>
                                        {
                                            galleryItem.map((item) => (
                                                <CourseItem data={item} key={Math.random()}></CourseItem>
                                            ))
                                        }
                                    </div>
                                );
                            })
                        }
                    </div>
                    <ul className={'cources__point-list'}>
                        <li className={'cources__arrow left'}>
                            <img
                                src={arrowLeft}
                                title={'left'}
                                alt={'left'}
                                onClick={() => {
                                    setSlideCount(slideCount - 1);
                                }}
                            />
                        </li>
                        {gallery.map((item, index) => {
                            return <li key={Math.random()}
                                       className={`point point${random} ${index === 0 ? 'active' : ''}`}
                                       onClick={() => {
                                           setSlideCount(index);
                                       }}></li>
                        })}
                        <li className={'cources__arrow right'}>
                            <img
                                src={arrowRight}
                                title={'right'}
                                alt={'right'}
                                onClick={() => {
                                    setSlideCount(slideCount + 1);
                                }}
                            />
                        </li>
                    </ul>
                </div>
                {events && events.length > 0 && (
                    <div className={'cources__events'}>
                        <HorizontalSlider items={events} title={'Події'} baseHref={'event'}></HorizontalSlider>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CoursesList;
