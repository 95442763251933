import React from "react";
import { NavLink } from "react-router-dom";
import "./Social.css";
import fb from "../../assets/images/fb.png";
import instagram from "../../assets/images/instagram.png";
import youtube from "../../assets/images/youtube.png";

function Social() {
  return (
    <nav className={'social'}>
      <ul>
        <li>
          <a href={'https://www.facebook.com/itlabnetua/'} target={'_blank'}>
            <img src={fb} title={'facebook'} alt={'facebook'}/>
          </a>
        </li>
        <li>
          <a href={'https://instagram.com/itlabnetua?utm_medium=copy_link'} target={'_blank'}>
            <img src={instagram} title={'instagram'} alt={'instagram'}/>
          </a>
        </li>
        <li>
          <a href={'https://www.youtube.com/channel/UCPolZba5BAD8vJqtmKT3vEg'} target={'_blank'}>
            <img src={youtube} title={'youtube'} alt={'youtube'}/>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Social;
