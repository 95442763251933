class FeedbackService {
    constructor() {
        this._apiBase = 'api.itl.net.ua';
    }

    async sendRequest(route, method = 'GET', data = {}, headers = {}) {
        const res = await fetch(`${this._apiBase}${route}`, {
            method,
            body: JSON.stringify(data),
            headers
        });

        if (!res.ok) return res.ok;
        return await res.json();
    }

    async sendFeedbackForm(data) {
        const res = await this.sendRequest(
            '/feedback',
            'POST',
            data,
            {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        );
        return res;
    }
}

export default FeedbackService;
