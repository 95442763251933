import React, {useState} from "react";
import style from "./PhotoModal.module.css";
import Modal from 'react-modal';
import MaterialIcon from "@material/react-material-icon";

Modal.setAppElement('#root');

function PhotoModal(props) {
    const {photo, closeParent} = props;
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div style={{
            zIndex: 1000000,
            position: 'absolute',
            width: '100%'
        }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => {
                    setIsOpen(false);
                    closeParent();
                }}
                contentLabel={'Photo'}
                className={style.videomodal}
                overlayClassName={style.videomodal__overlay}
                closeTimeoutMS={500}
            >
                <MaterialIcon
                    className={style.videomodal__close}
                    icon='close'
                    onClick={() => {
                        closeParent();
                    }}
                />
                <img src={photo} title={'Gallery image'} alt={'Gallery image'}/>
            </Modal>
        </div>
    );
}

export default PhotoModal;
