import React, {Component} from "react";
import {connect} from 'react-redux';
import "./Login.css";
import Input from '../../components/Input/Input';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import ActivityIndicator from '../../components/ActivityIndicator/ActivityIndicator';
import MaterialIcon from "@material/react-material-icon";
import {Link} from "react-router-dom";
import AuthService from "../../services/AuthService";
import SocialAuth from './SocialAuth/SocialAuth';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            loginError: false,
            passwordError: false,
            sendStatus: null,
            isSending: false
        }

        this.authService = new AuthService();

        this.sendLoginForm = this.sendLoginForm.bind(this);
    }

    validateForm() {
        let state = true;
        const {login, password} = this.state;

        if (!login) {
            this.setState({loginError: true, sendStatus: null});
            state = false;
        }

        if (!password) {
            this.setState({passwordError: true, sendStatus: null});
            state = false;
        }

        return state;
    }

    async sendLoginForm() {
        const isValid = this.validateForm();

        if (isValid) {
            const {login, password} = this.state;
            this.setState({isSending: true, loginError: false, passwordError: false});
            const result = await this.authService.basicLogin({login, password});

            setTimeout(() => {
                this.setState({isSending: false, sendStatus: result});
            }, 1000);
        }
    }

    render() {
        const {login, password, loginError, passwordError, sendStatus, isSending} = this.state;

        return (
            <div className={'login'}>
                <div className={'login__block'}>
                    <Link to={-1}><MaterialIcon className={'close'} icon='close'/></Link>
                    <h3>Вхід до особистого кабінета</h3>

                    {!isSending && (
                        <div className={'login__block-wrapper'}>
                            <SocialAuth/>

                            {(loginError || passwordError) &&
                                <b className={'login__error'}>Заповніть всі обов’язкові поля.</b>}
                            {sendStatus === false &&
                                <b className={'login__error'}>Виникла помилка, спробуйте ще раз.</b>}
                            <div className={'login__item'}>
                                <Input
                                    error={loginError}
                                    typeTitle={'Пошта або телефон'}
                                    text={login}
                                    oninput={(elem) => {
                                        this.setState({login: elem.target.value});
                                    }}
                                    validate={['email', 'phone']}
                                    required={true}></Input>
                            </div>
                            <div className={'login__item'}>
                                <PasswordInput
                                    error={passwordError}
                                    typeTitle={'Пароль'}
                                    text={password}
                                    oninput={(elem) => {
                                        this.setState({password: elem.target.value});
                                    }}
                                    required={true}></PasswordInput>
                            </div>
                            <Link to={'/forgot'} className={'login__forgot'}>Забули пароль?</Link>
                            <ButtonPrimary
                                type={'dark'}
                                text={'Війти'}
                                onclick={this.sendLoginForm}
                            ></ButtonPrimary>
                        </div>
                    )}

                    {isSending && (
                        <ActivityIndicator />
                    )}
                </div>
            </div>
        );
    }
}

export default connect()(Login);
