class CoursesService {
    constructor() {
        this._apiBase = 'https://api.itl.net.ua';
    }

    async sendRequest(route, method = 'GET', data = {}, headers = {}) {
        const res = await fetch(`${this._apiBase}${route}`, {
            method,
            body: JSON.stringify(data),
        });

        if (!res.ok) {
            throw new Error(`Could not fetch ${route}`);
        }
        return await res.json();
    }

    async getCoursesWithEvents() {
        //const res = await this.sendRequest('/courses');
        //return res;
        return [
            {
                title: 'Test',
                courses: [
                    {id: 1, title: 'Курс «Робототехніка та програмування»', image: '', ageRestrictions: 6},
                    {id: 2, title: 'Курс «Робототехніка та програмування»', image: '', ageRestrictions: 6}
                ],
                events: [
                    {id: 1, img: '', date: new Date()},
                    {id: 2, img: '', date: new Date()},
                    {id: 3, img: '', date: new Date()}
                ]
            },
            {
                title: 'Test1',
                courses: [
                    {id: 1, title: 'Курс «Робототехніка та програмування»', image: '', ageRestrictions: 6},
                    {id: 2, title: 'Курс «Робототехніка та програмування»', image: '', ageRestrictions: 6}
                ],
                events: [
                    {id: 1, img: '', date: new Date()},
                    {id: 2, img: '', date: new Date()},
                    {id: 3, img: '', date: new Date()}
                ]
            }
        ];
    }

    async getCourse(id) {
        const res = await this.sendRequest(`/courses?id=${id}`);
        return res;
    }
}

export default CoursesService;
