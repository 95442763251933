import style from './ConsultationRequest.module.css';
import ButtonPrimary from "../../../../components/ButtonPrimary/ButtonPrimary";
import {Link} from "react-router-dom";

import fbBg from '../../../../assets/images/fbBg.png';

function ConsultationRequest(){
    return (
        <div className={style.consultation} style={{
            backgroundImage: `url(${fbBg})`
        }}>
            <div className={style.consultationBlock}>
                <h3>Запит на консультацію</h3>
                <p>Залиште ваші контактні дані, і ми вам обов'язково зателефонуємо!</p>
                <Link to={'/feedback'}>
                    <ButtonPrimary text={'Заповнити форму'} type={'dark'} />
                </Link>
            </div>
        </div>
    );
}

export default ConsultationRequest;
