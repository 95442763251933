import React, {Component} from "react";
import "./Courses.css";
import CoursesList from './components/CoursesList/CoursesList';
import CoursesService from '../../../../services/CoursesService';


class Courses extends Component{
    constructor(props) {
        super(props);

        this.coursesService = new CoursesService();
        this.state = {
            courses: []
        }
    }

    async componentDidMount(){
        const courses = await this.coursesService.getCoursesWithEvents();
        this.setState({courses});
    }

    render(){
        const {courses} = this.state;

        return (
            <section id={'cources'}>
                <div className={'cources__wrapper'}>
                    <h2>Навчання</h2>
                    {
                        courses.map((course) =>
                            <CoursesList title={'Робототехніка та програмування'}
                                         data={
                                             [
                                                 {
                                                     id: 1,
                                                     title: 'Курс «Робототехніка та програмування»',
                                                     image: '',
                                                     ageRestrictions: 6
                                                 },
                                                 {
                                                     id: 2,
                                                     title: 'Курс «Робототехніка та програмування»',
                                                     image: '',
                                                     ageRestrictions: 6
                                                 }
                                             ]
                                         }
                                         events={
                                             [
                                                 {id: 1, img: '', date: new Date()},
                                                 {id: 2, img: '', date: new Date()},
                                                 {id: 3, img: '', date: new Date()}
                                             ]
                                         }>
                            </CoursesList>
                        )
                    }
                </div>
            </section>
        );
    }
}

export default Courses;
