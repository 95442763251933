import React from "react";
import "./Main.css";
import mainVideo from '../../../../assets/video/main.mp4';
import ButtonPrimary from "../../../../components/ButtonPrimary/ButtonPrimary";
import {Link} from "react-router-dom";

function Main() {
    return (
        <section id={'main'}>
            <div className={'main__wrapper'}>
                <div className={'main__block'}>
                    <div className={'main__item main__item_filter'}>
                        <video autoPlay={true} muted={true} loop={true}>
                            <source src={mainVideo} type={"video/mp4"}/>
                        </video>
                        <div className={'main__info'}>
                            <div className={'main__info-wrapper'}>
                                <h1>Побудуй свій шлях до IT-майбутнього разом з <br/><span>ITLab!</span></h1>
                                <Link to={'/feedback'}><ButtonPrimary text={'Дізнатись більше'}></ButtonPrimary></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Main;
