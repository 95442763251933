import React from "react";
import "./Content.css";
import {HorizontalSlider} from "../../../../components";

function Content({post}) {
    const {type, title, image, text, date, neighborPost} = post;
    const newsData = {
        news: [
            {
                id: 1,
                date: new Date(),
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s',
            },
            {
                id: 2,
                date: new Date(),
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s'
            },
            {
                id: 3,
                date: new Date(),
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s'
            }
        ],
        href: '/news'
    };

    return (
        <section className={'course__content'}>
            <div className={'course__content-wrapper'}>
                <div className={'post__content-info'}>
                    <p className={'post__content-text'}>{text}</p>
                </div>
                <HorizontalSlider items={newsData.news} title={'Оголошення'}
                                  baseHref={newsData.href}></HorizontalSlider>
            </div>
        </section>
    );
}

export default Content;
