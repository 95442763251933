import React from "react";
import "./Textarea.css";

function Textarea(props) {
    return (
        <div className={`textarea__wrapper ${props.disabled ? 'disabled' : ''}`}>
            <label>{props.typeTitle}{props.required ? <span className={'textarea__required'}>*</span> : ''}</label>
            <textarea
                className={`textarea__field ${props.active ? 'active' : ''} ${props.error ? 'error' : ''}`}
                value={props.text}
                onInput={props.oninput}
                required={props.required}
                placeholder={props.placeholder}
                disabled={props.disabled}
            ></textarea>
        </div>
    );
}

export default Textarea;
