import {MODAL_FEEDBACK_STATUS, MODAL_LOGIN_STATUS} from '../types';

const INITIAL_STATE = {
    feedbackActive: false,
    loginActive: false
};

const modalReducer = function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case MODAL_FEEDBACK_STATUS: {
            return {
                ...state,
                feedbackActive: action.payload.active
            }
        }
        case MODAL_LOGIN_STATUS: {
            return {
                ...state,
                loginActive: action.payload.active
            }
        }
    }
};

export default modalReducer;
