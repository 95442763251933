import React from "react";
import "./Main.css";
import {Link} from "react-router-dom";

import arrowLeft from '../../../../assets/images/arrow-left-light.png';
import arrowRight from '../../../../assets/images/arrow-right-light.png';
import {ButtonPrimary} from "../../../../components/index";

function Content({post}) {
    const {type, title, image, date, neighborPost} = post;

    return (
        <section className={'post__main'} style={{
            backgroundImage: `url(${image})`
        }}>
            <div className={'post__main-wrapper'}>
                <div className={'post__main-info'}>
                    <h1>{title}</h1>
                    <div className={'post__main-info-date'}>{date.toLocaleDateString('en-GB')}</div>
                    <Link to={'/feedback'} className={'post__main-info-more'}>
                        <ButtonPrimary text={"Дізнатись більше"}></ButtonPrimary>
                    </Link>
                </div>
            </div>
        </section>
    );
}

export default Content;
