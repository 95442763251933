import React from "react";
import "./SocialShare.css";
import telegram from "../../assets/images/telegram.png";
import facebook from "../../assets/images/facebookOut.png";
import messenger from '../../assets/images/messenger.png';
import viber from '../../assets/images/viber.png';
import whatsapp from "../../assets/images/whatsapp.png";
import share from '../../assets/images/share.png';

function SocialShare({url, text}) {
    return (
        <div className={'social-share'}>
            <h4>Поділитись:</h4>
            <ul>
                <li>
                    <a href={`https://t.me/share/url?url=${url}&text=${text}`} target={'_blank'}>
                        <img src={telegram}/>
                    </a>
                </li>
                <li>
                    <a href={`https://www.facebook.com/sharer/sharer.php?u=${url}`} target={'_blank'}>
                        <img src={facebook}/>
                    </a>
                </li>
                <li>
                  <a href={`https://msng.link/o/?${url}=fm`} target={'_blank'}>
                    <img src={messenger}/>
                  </a>
                </li>
                <li>
                  <a href={`viber://forward?text=${text} ${url}`} target={'_blank'}>
                    <img src={viber}/>
                  </a>
                </li>
{/*                <li>
                    <img src={whatsapp}/>
                </li>*/}
                <li>
                    <img src={share}/>
                </li>
            </ul>
        </div>
    );
}

export default SocialShare;
