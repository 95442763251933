import React from "react";
import "./Peculiarities.css";
import startup from '../../../../assets/images/startup_2.png';

function Peculiarities() {
  return (
      <section id={'peculiarities'}>
        <div className={'peculiarities__wrapper'}>
            <h2>Наші особливості</h2>
            <div className={'peculiarities__info'}>
                <details>
                    <summary>Індивідуальний підхід до кожного</summary>
                    <p>
                        Ми розробили зручну та інформативну платформу для навчання наших учнів в лабораторії. Навчальна платформа допомагає відстежувати...
                    </p>
                </details>
                <details>
                    <summary>Можливість розробки наукової роботи</summary>
                    <p>
                        Ми розробили зручну та інформативну платформу для навчання наших учнів в лабораторії. Навчальна платформа допомагає відстежувати...
                    </p>
                </details>
                <details>
                    <summary>Особистий кабінет учня/учениці на сайті</summary>
                    <p>
                        Ми розробили зручну та інформативну платформу для навчання наших учнів в лабораторії. Навчальна платформа допомагає відстежувати...
                    </p>
                </details>
                <details>
                    <summary>Індивідуальний підхід до кожного.</summary>
                    <p>
                        Ми розробили зручну та інформативну платформу для навчання наших учнів в лабораторії. Навчальна платформа допомагає відстежувати...
                    </p>
                </details>
            </div>
            <div className={'peculiarities__image'}>
               <div className={'peculiarities__image_wrapper'}>
                   <img src={startup} title={'rocket'} alt={'rocket'}/>
               </div>
            </div>
        </div>
      </section>
  );
}

export default Peculiarities;
