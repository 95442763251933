import React, {Component} from "react";
import {connect} from 'react-redux';
import "./Feedback.css";
import Input from '../../components/Input/Input';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import ActivityIndicator from '../../components/ActivityIndicator/ActivityIndicator';
import MaterialIcon from "@material/react-material-icon";
import Textarea from "../../components/Textarea/Textarea";
import FeedbackService from "../../services/FeedbackService";
import {Link} from "react-router-dom";

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            phone: '',
            comment: '',
            nameError: false,
            phoneError: false,
            sendStatus: null,
            isSending: false
        }

        this.feedbackService = new FeedbackService();
        this.sendFeedbackForm = this.sendFeedbackForm.bind(this);
    }

    validateForm() {
        let state = true;
        const {name, phone} = this.state;

        if (!name) {
            this.setState({nameError: true});
            state = false;
        }

        if (!phone) {
            this.setState({phoneError: true});
            state = false;
        }

        return state;
    }

    async sendFeedbackForm() {
        const isValid = this.validateForm();

        if (isValid) {
            this.setState({isSending: true});
            const result = await this.feedbackService.sendFeedbackForm({});

            setTimeout(() => {
                this.setState({isSending: false, sendStatus: result});
            }, 1000);
        }
    }

    render() {
        const {name, phone, comment, nameError, phoneError, sendStatus, isSending} = this.state;
        const isValidForm = name !== "" && phone !== "";

        return (
            <div className={'feedback'}>
                <div className={'feedback__block'}>
                    <Link to={-1}><MaterialIcon className={'close'} icon='close'/></Link>
                    <h3>Контактна форма для зв’язку</h3>

                    {isSending && (
                        <ActivityIndicator />
                    )}

                    {sendStatus === null && !isSending && (
                        <div className={'feedback__block-wrapper'}>
                            <p>Щоб дізнатись більше про IT Lab, будь ласка, залиште свої контактні дані.</p>
                            {(nameError || phoneError) &&
                                <b className={'feedback__error'}>Заповніть всі обов’язкові поля.</b>}
                            <div className={'feedback__item'}>
                                <Input
                                    error={nameError}
                                    typeTitle={'Ім’я'}
                                    text={name}
                                    oninput={(elem) => {
                                        this.setState({name: elem.target.value});
                                    }}
                                    required={true}></Input>
                            </div>
                            <div className={'feedback__item'}>
                                <Input
                                    error={phoneError}
                                    typeTitle={'Телефон'}
                                    text={phone}
                                    validate={['phone']}
                                    oninput={(elem) => {
                                        this.setState({phone: elem.target.value});
                                    }}
                                    required={true}></Input>
                            </div>
                            <div className={'feedback__item'}>
                                <Textarea
                                    typeTitle={'Коментар'}
                                    text={comment}
                                    oninput={(elem) => {
                                        this.setState({comment: elem.target.value});
                                    }}
                                    required={false}></Textarea>
                            </div>
                            <ButtonPrimary
                                type={'dark'}
                                text={'Відправити'}
                                onclick={this.sendFeedbackForm}
                                disabled={!isValidForm}
                            ></ButtonPrimary>
                        </div>
                    )}

                    {sendStatus === false && (
                        <>
                            <b className={'feedback__server-error'}>Виникла помилка! Будь ласка, спробуйте ще раз.</b>
                            <ButtonPrimary
                                type={'dark'}
                                text={'Заповнити знову'}
                                onclick={() => {
                                    this.setState({sendStatus: null});
                                }}
                            ></ButtonPrimary>
                        </>
                    )}

                    {sendStatus === true && (
                        <>
                            <b className={'feedback__success'}>Дякуємо! В найближчий час очікуйте повідомлення.</b>
                            <Link to={'/'}>
                                <ButtonPrimary
                                    type={'dark'}
                                    text={'Закрити форму'}
                                ></ButtonPrimary>
                            </Link>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default connect()(Feedback);
