import React, {useState} from "react";
import "./PasswordInput.css";
import MaterialIcon from "@material/react-material-icon";

function PasswordInput(props) {
    const {
        disabled,
        typeTitle,
        required,
        text,
        active,
        error,
        oninput,
        placeholder,
    } = props;

    const [hide, setHide] = useState(true);

    return (
        <div className={`input-pass__wrapper ${disabled ? 'disabled' : ''}`}>
            <label>{typeTitle}{required ? <span className={'input-pass__required'}>*</span> : ''}</label>
            <input
                type={hide ? 'password' : 'text'}
                value={text}
                className={`input-pass__field ${active ? 'active' : ''} ${error ? 'error' : ''}`}
                onInput={(e) => {
                    oninput(e);
                }}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
            ></input>
            <div className={'input-pass__toggle'} onClick={() => setHide((prev) => !prev)}>
                {hide ? (<MaterialIcon icon='visibility' />) : (<MaterialIcon icon='visibility_off' />)}
            </div>
        </div>
    );
}

export default PasswordInput;
