import React, {useState} from "react";
import "./VideoModal.css";
import Modal from 'react-modal';
import MaterialIcon from "@material/react-material-icon";

Modal.setAppElement('#root');

function VideoModal(props) {
    const {videoURL, closeParent} = props;
    const [isOpen, setIsOpen] = useState(true);

    return (
        <div style={{
            zIndex: 1000000,
            position: 'absolute'
        }}>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => {
                    setIsOpen(false);
                    closeParent();
                }}
                contentLabel={'Video'}
                className="videomodal"
                overlayClassName="videomodal__overlay"
                closeTimeoutMS={500}
            >
                <MaterialIcon
                    className={'videomodal__close'}
                    icon='close'
                    onClick={() => {
                        closeParent();
                    }}
                />
                <iframe width="100%" height="700px" src={videoURL}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen>
                </iframe>
            </Modal>
        </div>
    );
}

export default VideoModal;
