import React, {useState} from "react";
import "./Navigation.css";
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import logo from "../../assets/images/logo.png";
import MaterialIcon from "@material/react-material-icon";
import {HashLink as Link} from 'react-router-hash-link';

function Navigation() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    window.onscroll = () => {
        const header = document.querySelector('header');
        const y = window.pageYOffset !== undefined ? window.pageYOffset : window.scrollTop;

        if (y > window.innerHeight - 100) {
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
    }

    window.onresize = () => {
      const width = window.innerWidth;
      if(width > 980) setShowMobileMenu((oldState) => false);
    }

    return (
        <header>
            <label htmlFor={'header__toggle'} onClick={() => {
                setShowMobileMenu((oldState) => !oldState);
            }}>
                <MaterialIcon icon='menu'/>
            </label>
            <input id={'header__toggle'} type={"checkbox"} checked={showMobileMenu}/>
            <nav onClick={() => {
              const width = window.innerWidth;
              if(width <= 980) setShowMobileMenu((oldState) => !oldState);
            }}>
                <ul>
                    <li className={'header__logo'}>
                        <Link to={'/'} smooth>
                            <img src={logo} title={'ITL logo'} alt={'ITL_logo'}/>
                        </Link>
                    </li>
                    <li>
                        <Link to={'/#cources'} smooth>
                            Навчання
                        </Link>
                    </li>
                    <li>
                        <Link to={'/#peculiarities'} smooth>
                            Наші особливості
                        </Link>
                    </li>
                    <li>
                        <Link to={'/#news'} smooth>
                            Про нас
                        </Link>
                    </li>
                    <li>
                        <Link to={'/#footer'} smooth>
                            Контакты
                        </Link>
                    </li>
                </ul>
                <Link to={'/login'}>
                    <ButtonPrimary text={"Особливий кабінет"}></ButtonPrimary>
                </Link>
            </nav>
        </header>
    );
}

export default Navigation;
