import React from "react";
import "./CourseItem.css";
import ButtonPrimary from '../../../../../../../../components/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '../../../../../../../../components/ButtonSecondary/ButtonSecondary';
import {Link} from "react-router-dom";


function CourseItem(props) {
    const course = props.data;

    return (
        <div className={'course'}>
            <div className={'course__img'}>
                <img src={require('./1.jpeg')} title={course.title} alt={course.title}/>
                <span>{`${course.ageRestrictions}+`}</span>
            </div>
            <div className={'course__footer'}>
                <p>{course.title}</p>
                <div className={'course__buttons'}>
                    <Link to={'/feedback'}>
                        <ButtonPrimary type={'dark'} text={'Записатись на курс'}></ButtonPrimary>
                    </Link>
                    <Link to={`/course/${course.id}`}>
                        <ButtonSecondary text={'Детальніше про курс'} active={true}/>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default CourseItem;
