import React from "react";
import "./Index.css";
import Social from '../../components/Social/Social';
import Main from '../Index/components/Main/Main';
import Courses from '../Index/components/Cources/Courses';
import Peculiarities from '../Index/components/Peculiarities/Peculiarities';
import News from '../Index/components/News/News';
import { connect } from 'react-redux';

function Index() {
  return (
      <div className={'wrapper'}>
        <Social></Social>
        <Main></Main>
        <Courses></Courses>
        <Peculiarities></Peculiarities>
        <News title={'Про нас'}/>
      </div>
  );
}

const mapStateToProps = (state) => {
    return {

    }
}

export default connect(mapStateToProps)(Index);
