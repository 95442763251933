import React, {useState} from "react";
import "./Input.css";
import {validateEmail, validatePhone} from "../../utils";
import MaterialIcon from "@material/react-material-icon";

function Input(props) {
    const {
        disabled,
        typeTitle,
        required,
        type,
        text,
        active,
        error,
        oninput,
        placeholder,
        validate
    } = props;
    const [valid, setValid] = useState(null);

    return (
        <div className={`input__wrapper ${disabled ? 'disabled' : ''}`}>
            <label>{typeTitle}{required ? <span className={'input__required'}>*</span> : ''}</label>
            <input
                type={type}
                value={text}
                className={`input__field ${active ? 'active' : ''} ${error ? 'error' : ''}`}
                onInput={(e) => {
                    oninput(e);

                    if(Array.isArray(validate) && validate.length > 0){
                        const text = e.target.value;

                        for(let i = 0; i < validate.length; i++){
                            if(validate[i] === 'email') {
                                const state = validateEmail(text);
                                setValid(state);
                                if(state) break;
                            }else if(validate[i] === 'phone'){
                                const state = validatePhone(text);
                                setValid(state);
                                if(state) break;
                            }
                        }

                        if(text.length === 0) setValid(null);
                    }
                }}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
            ></input>
            {valid === true && (
                <div className={'input__valid'}><MaterialIcon icon='check'/></div>
            )}

            {valid === false && (
                <div className={'input__valid not'}><MaterialIcon icon='close'/></div>
            )}
        </div>
    );
}

export default Input;
