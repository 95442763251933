import React, {useState} from "react";
import "./Post.css";
import Main from './components/Main/Main';
import Content from './components/Content/Content';
import {Social} from '../../components/index';
import {connect} from 'react-redux';
import {ActivityIndicatorBlock, ErrorBlock} from "../../components/index";

function Post({type}) {
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState(false);
    const post = {
        image: 'https://img.freepik.com/free-photo/milford-sound-new-zealand-travel-destination-concept_53876-42945.jpg?w=2000',
        title: 'Оголошення набору на курс для дітей від 6 років',
        text: 'Курс Front-End для школярів розрахований на хлопців та дівчаток віком c 14 до 17 років і передбачає вивчення технологій, які використовуються практикуючими програмістами для створення веб-сайтів. Після закінчення курсу дитина і його батьки зможуть визначитися, чим ій краще займатися у майбутньому. Курс Front-End для школярів розрахований на хлопців та дівчаток віком c 14 до 17 років і передбачає вивчення технологій, які використовуються практикуючими програмістами для створення веб-сайтів. Після закінчення курсу дитина і його батьки зможуть визначитися, чим ій краще займатися у майбутньому.',
        date: new Date(),
        neighborPost: [1, 3],
        type
    };

    setTimeout(() => setLoading(false), 1000);

    if(loading) return <ActivityIndicatorBlock/>
    else if(serverError) return <ErrorBlock/>

    return (
        <div className={'wrapper'}>
            <Social></Social>
            <Main post={post}></Main>
            <Content post={post}></Content>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {}
}

export default connect(mapStateToProps)(Post);
