import React, {Component} from "react";
import {connect} from 'react-redux';
import "./Forgot.css";
import Input from '../../components/Input/Input';
import ButtonPrimary from '../../components/ButtonPrimary/ButtonPrimary';
import ActivityIndicator from '../../components/ActivityIndicator/ActivityIndicator';
import MaterialIcon from "@material/react-material-icon";
import AuthService from "../../services/AuthService";
import {Link} from "react-router-dom";

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            loginError: '',
            sendStatus: null,
            isSending: false
        }

        this.authService = new AuthService();
        this.sendForgotForm = this.sendForgotForm.bind(this);
    }

    validateForm() {
        let state = true;
        const {login} = this.state;

        if (!login) {
            this.setState({loginError: true});
            state = false;
        }

        return state;
    }

    async sendForgotForm() {
        const isValid = this.validateForm();

        if (isValid) {
            const {login} = this.state;
            this.setState({isSending: true});
            const result = await this.authService.forgotPassword({login});

            setTimeout(() => {
                this.setState({isSending: false, sendStatus: result});
            }, 1000);
        }
    }

    render() {
        const {login, loginError, sendStatus, isSending} = this.state;

        return (
            <div className={'forgot'}>
                <div className={'feedback__block'}>
                    <Link to={-1}><MaterialIcon className={'close'} icon='close'/></Link>
                    <h3>Відновлення паролю</h3>

                    {isSending && (
                        <ActivityIndicator />
                    )}

                    {sendStatus === null && !isSending && (
                        <div className={'feedback__block-wrapper'}>
                            <p>Щоб відновити пароль, введіть нижче вашу адресу електронної пошти. На вашу електронну пошту буде надіслано лист з інструкціями, як відновити доступ до системи.</p>
                            {(loginError) &&
                                <b className={'feedback__error'}>Заповніть всі обов’язкові поля.</b>}
                            <div className={'feedback__item'}>
                                <Input
                                    error={loginError}
                                    typeTitle={'Пошта/Телефон'}
                                    text={login}
                                    oninput={(elem) => {
                                        this.setState({login: elem.target.value});
                                    }}
                                    validate={['email', 'phone']}
                                    required={true}></Input>
                            </div>
                            <ButtonPrimary
                                type={'dark'}
                                text={'Відправити'}
                                onclick={this.sendForgotForm}
                            ></ButtonPrimary>
                        </div>
                    )}

                    {sendStatus === false && (
                        <>
                            <b className={'feedback__server-error'}>Виникла помилка! Будь ласка, спробуйте ще раз.</b>
                            <ButtonPrimary
                                type={'dark'}
                                text={'Заповнити знову'}
                                onclick={() => {
                                    this.setState({sendStatus: null});
                                }}
                            ></ButtonPrimary>
                        </>
                    )}

                    {sendStatus === true && (
                        <>
                            <b className={'feedback__success'}>Дякуємо! Лист з інструкціями по відновленню паролю вже на вашій електронній пошті. Якщо у вас з цим виникнуть питання, зв'яжіться з адміністратором сайту.</b>
                            <Link to={'/'}>
                                <ButtonPrimary
                                    type={'dark'}
                                    text={'Закрити форму'}
                                ></ButtonPrimary>
                            </Link>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default connect()(Forgot);
