import React from "react";
import ReactDOM from "react-dom";
import "./media.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {Footer, Navigation,} from "./components";
import store from './store/store/index';

import {Index, Feedback, Login, Forgot, Post, Course} from "./pages";
import {Provider} from 'react-redux';

const pathname = window.location.pathname;

ReactDOM.render(
    <Provider store={store}>
        <Router>
            {(pathname !== 'feedback' && pathname !== 'login' && pathname !== 'forgot') && <Navigation/>}
            <Routes>
                <Route path="/" element={<Index/>}/>
                <Route path="/feedback" element={<Feedback/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/forgot" element={<Forgot/>}/>
                <Route path="/news/:id" element={<Post type={'news'}/>}/>
                <Route path="/event/:id" element={<Post type={'event'}/>}/>
                <Route path="/course/:id" element={<Course/>}/>
            </Routes>
            {(pathname !== 'feedback' && pathname !== 'login' && pathname !== 'forgot') && <Footer/>}
        </Router>
    </Provider>,

    document.getElementById("root")
);

console.log('%c ITL Odesa', 'font-weight: bold; font-size: 72px;color: #00ffff; text-shadow: 3px 3px 0 #00ECEC, 6px 6px 0 #80FFFF, 9px 9px 0 #070649, 12px 12px 0 #070649, 15px 15px 0 #070649, 18px 18px 0 #070649, 21px 21px 0 #070649');

serviceWorker.unregister();

