import React from "react";
import {HorizontalSlider} from '../../../../components/index';
import NewsGallery from './components/NewsGallery/NewsGallery';
import "./News.css";

function News({title = ''}) {
    const newsData = {
        gallery: [
            {
                id: 1,
                type: 'photo',
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s',
            },
            {
                id: 2,
                type: 'photo',
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s',
            },
            {
                id: 3,
                type: 'photo',
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s',
            },
            {
                id: 4,
                type: 'video',
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s',
            },
        ],
        news: [
            {
                id: 1,
                date: new Date(),
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s',
            },
            {
                id: 2,
                date: new Date(),
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s'
            },
            {
                id: 3,
                date: new Date(),
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s'
            }
        ],
        href: '/news'
    };

    return (
        <section id={'news'}>
            <h2>{title}</h2>
            <div className={'news__wrapper'}>
                <NewsGallery gallery={newsData.gallery}></NewsGallery>
                <HorizontalSlider items={newsData.news} title={'Новини'} baseHref={newsData.href}></HorizontalSlider>
            </div>
        </section>
    );
}

export default News;
