import React from "react";
import "./ActivityIndicatorBlock.css";
import {ActivityIndicator} from "../index";

function ActivityIndicatorBlock(props) {
    return (
        <div className={'activity-indicator__wrapper'}>
            <ActivityIndicator/>
        </div>
    );
}

export default ActivityIndicatorBlock;
