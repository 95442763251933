import React, {useEffect, useState} from "react";
import "./HorizontalSlider.css";

import arrowLeft from '../../assets/images/arrow-left.png';
import arrowRight from '../../assets/images/arrow-right.png';
import ButtonPrimary from "../ButtonPrimary/ButtonPrimary";
import {Link} from "react-router-dom";


function HorizontalSlider({items, title, baseHref}) {
    const [slideCount, setSlideCount] = useState(0);
    const random = Math.random().toString().replace('.', 'a');

    useEffect(() => {
        let activeSlideIndex = slideCount;

        if(slideCount < 0){
            setSlideCount(items.length - 1);
            activeSlideIndex = items.length - 1;
        }else if(slideCount >= items.length){
            setSlideCount(0);
            activeSlideIndex = 0;
        }

        const points = document.querySelectorAll(`.point${random}`);

        if(points.length > 0) {
            points.forEach((point) => {
                point.classList.remove('active');
            });

            points[activeSlideIndex].classList.add('active');
        }
    });

    return (
        <div className={'slider'}>
            {title && (<h2>{title}</h2>)}
            <div className={'slider__wrapper'}>
                <ul>
                    <li className={'slider__arrow left'}>
                        <img
                            src={arrowLeft}
                            title={'Slider left'}
                            alt={'Slider left'}
                            onClick={() => {
                                setSlideCount(slideCount - 1);
                            }}
                        />
                    </li>
                    {items.map((event, index) => {
                        return (
                            <li
                                key={Math.random()}
                                className={`point point${random} ${index === 0 ? 'active' : ''}`}
                                onClick={() => {
                                    setSlideCount(index);
                                }}
                            ></li>
                        );
                    })}
                    <li className={'slider__arrow right'}>
                        <img
                            src={arrowRight}
                            title={'Slider-right'}
                            alt={'Slider-right'}
                            onClick={() => {
                                setSlideCount(slideCount + 1);
                            }}
                        /></li>
                </ul>

                <div className="slider__block">
                    <div
                        className={'slider__section'}
                        style={{
                            left: -320 * slideCount
                        }}
                    >
                        {
                            items.map((newsItem) => {
                                return (
                                    <div key={Math.random()} className={'slider__item'} data-id={newsItem.id}>
                                        <div className="slider__img">
                                            <img src={'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s'} alt={'Event image'}/>
                                        </div>
                                        <div className="slider__footer">
                                            <Link to={`${baseHref}/${newsItem.id}`}>
                                                <ButtonPrimary type={'dark'} text={'Детальніше'}></ButtonPrimary>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


export default HorizontalSlider;
