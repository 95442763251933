import React from "react";
import "./Content.css";
import {HorizontalSlider, SocialShare} from "../../../../components";
import CoursesList from "../../../Index/components/Cources/components/CoursesList/CoursesList";

function Content({post}) {
    const {type, title, image, text, date, neighborPost} = post;
    const newsData = {
        news: [
            {
                id: 1,
                date: new Date(),
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s',
            },
            {
                id: 2,
                date: new Date(),
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s'
            },
            {
                id: 3,
                date: new Date(),
                src: 'https://miro.medium.com/max/700/0*Hm2SnFNk_-gdvN1s'
            }
        ],
        href: '/news'
    };

    return (
        <section className={'post__content'}>
            <div className={'post__content-info'}>
                <p className={'post__content-text'}>{text}</p>
                <SocialShare url={window.location.href} text={title}/>
            </div>
            <HorizontalSlider items={newsData.news} title={type === 'news' ? 'Схожі новини' : 'Схожі події'}
                              baseHref={newsData.href}></HorizontalSlider>
            <CoursesList title={'Популярні курси'}
                         data={
                             [
                                 {
                                     id: 1,
                                     title: 'Курс «Робототехніка та програмування»',
                                     image: '',
                                     ageRestrictions: 6
                                 },
                                 {
                                     id: 2,
                                     title: 'Курс «Робототехніка та програмування»',
                                     image: '',
                                     ageRestrictions: 6
                                 },
                                 {
                                     id: 3,
                                     title: 'Курс «Робототехніка та програмування»',
                                     image: '',
                                     ageRestrictions: 6
                                 }
                             ]
                         }
                         full={true}
                         count={3}
            >
            </CoursesList>
        </section>
    );
}

export default Content;
