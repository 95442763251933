import React, {Component} from "react";
import {connect} from 'react-redux';
import "./SocialAuth.css";
import AuthService from "../../../services/AuthService";

const googleLogo = require('../../../assets/images/auth_google.png');
const facebookLogo = require('../../../assets/images/auth_fb.png');

class SocialAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.authService = new AuthService();

        //this.sendLoginForm = this.sendLoginForm.bind(this);
    }

    render() {
        return (
            <ul className={'login__social'}>
                <li className={'disabled'}>
                    <img src={googleLogo}/>
                    <span>Google</span>
                </li>
                <li className={'disabled'}>
                    <img src={facebookLogo}/>
                    <span>Facebook</span>
                </li>
            </ul>
        );
    }
}

export default connect()(SocialAuth);
