import React from "react";
import "./ErrorBlock.css";
import {ButtonPrimary} from "../index";

function ErrorBlock({errorText = 'Помилка серверу! Будь ласка, спробуйте пізніше'}) {
    return (
        <div className={'error-block__wrapper'}>
            <b>{errorText}</b>
            <ButtonPrimary
                text={"Перезавантажити"}
                onclick={() => window.location.reload()}
            />
        </div>
    );
}

export default ErrorBlock;
